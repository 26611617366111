<div id="container" class="l-constrained">
  <app-nav-main></app-nav-main>
  <section class="head-vid">
    <div class="container">
      <div class="title text-white">
        <div>
          <h1>Where change begins</h1>
        </div>
      </div>
    </div>
    <img src="assets/images/home-banners/where-change-begins-radius-recycling.jpg" alt="Radius Recycling" />
  </section>
  <section class="container ptb-90">
    <div class="row">
      <div class="col-xl-6 pr-90 mb-xl-0 mb-5">
        <div class="d-block text-center text-md-start d-md-flex justify-content-between">
          <h2 class="home-title">Named to TIME's List of the Most Influential Companies of 2023</h2>
          <div><a href="https://time.com/collection/time100-companies-2023/6285214/schnitzer-steel-industries/"
              target="_black"><img src="assets/images/awards/time-most-influential-companies.png"
                alt="2023 - TIME Most Influential Companies" /></a></div>
        </div>
        <div class="row gird-blocks mt-5">
          <div class="col-md-4">
            <a [routerLink]="'/locations'">
              <img src="assets/images/location.jpg" alt="" />
              <h4>Locations</h4>
            </a>
          </div>
          <div class="col-md-4 my-md-0 my-5">
            <a [routerLink]="'/buy-from-us'">
              <img src="assets/images/products.jpg" alt="" />
              <h4>Products</h4>
            </a>
          </div>
          <div class="col-md-4">
            <a [routerLink]="'/service-lines/vehicle-recycling-services'">
              <img src="assets/images/services.jpg" alt="" />
              <h4>Services</h4>
            </a>
          </div>
        </div>
      </div>
      <div class="col-xl-6">
        <h3>Latest News</h3>
        <div class="latest_news_item">


          <owl-carousel-o [options]="customOptions" #owlNews>
            <ng-container *ngFor="let news of newsItems">
              <ng-template carouselSlide [id]="news.id" class="col-md-6">
                <div class="news-release-row slide" [routerLink]="['/company/investors/news-release-details/', news.id]">
                  <a [routerLink]="['/company/investors/news-release-details/', news.id]">{{news.date |
                    date:'MM/dd/yyyy'}}</a>
                  <p class="tight">{{news.title}}</p>
                </div>
              </ng-template>
            </ng-container>
          </owl-carousel-o>

          <div class="owl-nav">
            <button class="owl-prev" (click)="owlNews.prev()"><i class="fa-solid fa-angle-left"></i></button>
            <button class="owl-next" (click)="owlNews.next()"><i class="fa-solid fa-angle-right"></i></button>
          </div>
          <div class="text-center mt-4">
            <a [routerLink]="'/company/investors/news-releases'" class="btn btn-secondary">View All News</a>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section class="container circular-economy">
    <div class="row align-items-center">
      <div class="col-md-7">
        <div class="title text-white">
          <h1>Radius Recycling</h1>
          <span>At the center of the circular economy</span>
        </div>
      </div>
      <div class="col-md-5 text-center">
        <a href="javascript:;" data-bs-toggle="modal" data-bs-target="#RadiusRecyclingVidoe" id="HomeHeroVideo" class="play-btn" (click)="openModal('videoModal');playPause()"><img
            src="assets/images/icons/play-icon.png" alt="Play Video" /></a>
      </div>
    </div>
    <ngx-smart-modal #videoModal [identifier]="'videoModal'"
          customClass="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-lg" animation="slide">
          <div class="modal-content">
          <video controls id="videoPlayer" #videoPlayer>
            <source src="assets/videos/radius-recycling.mp4" type="video/mp4">
            Your browser does not support the video tag.
          </video>
        </div>
        </ngx-smart-modal>
  </section>
  <section class="container ptb-90 find-location">
    <div id="find-a-location">
      <app-location-search Theme="theme1" Title="Find a Location"></app-location-search>
    </div>
    <div>
    </div>
  </section>
  <div id="footer">
    <app-footer></app-footer>
  </div>
</div>
